import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KurzB2bUnitChooserComponent } from './kurz-b2b-unit-chooser.component';
import { DropdownModule } from '@util/components/dropdown/dropdown.module';



@NgModule({
  declarations: [
    KurzB2bUnitChooserComponent
  ],
  imports: [
    CommonModule,
    DropdownModule
  ],
  exports: [
    KurzB2bUnitChooserComponent
  ]
})
export class KurzB2bUnitChooserModule { }
